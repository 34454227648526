@use "../../base/utility-classes/overlay-container";

@use "../../base/components/spinner-overlay";

.dremio-spinner-overlay {
  background: var(--fill--primary);

  .dremio-spinner {
    --dremio--spinner--size: 30px;
  }
}
