/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */


$font-xs: 12px;
$font-s: 13px;
$font-m: 16px;
$font-l: 18px;
$font-xl: 22px;
$font-xxl: 26px;
$font-xxxl: 30px;
