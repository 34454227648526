/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */

@use "../../node_modules/@dremio/design-system/scss/variables/index" as
  variables;
@use "../../node_modules/@dremio/design-system/scss/utility-classes/index";
@import "./fonts/index.scss";

.dremio-auto-system {
  @supports not (prefers-color-scheme: light) {
    @include variables.light;
    color-scheme: light;
  }
  @media (prefers-color-scheme: light) {
    @include variables.light;
    color-scheme: light;
  }
  @media (prefers-color-scheme: dark) {
    @include variables.dark;
    color-scheme: dark;
  }
}

.dremio-light {
  @include variables.light;
}

.dremio-dark {
  @include variables.dark;
}

body {
  font-family: $font-family;
  font-size: $font-s;
  font-weight: 400;
  color: var(--text--primary);
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "calt" on, "cv08" on;
  margin: 0;
  a,
  .link {
    text-decoration: none;
    color: var(--text--link);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    font-size: $font-xl;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }

  h2 {
    font-size: $font-l;
    font-weight: 500;
  }

  h3 {
    font-size: $font-m;
    font-weight: 500;
  }

  * {
    box-sizing: border-box;
  }
}
