/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.dremio-tooltip {
  background: var(--dremio-tooltip--background);
  border-radius: var(--dremio-tooltip--border-radius);
  box-shadow: var(--dremio-tooltip--shadow);
  color: var(--dremio-tooltip--color);
  line-height: var(--dremio--tooltip--line-height);
  font-size: var(--dremio-tooltip--font-size);
  font-weight: var(--dremio-tooltip--font-weight);
  padding-block: calc(var(--dremio-tooltip--padding) - 2px);
  padding-inline: var(--dremio-tooltip--padding);
  z-index: 1000;

  &--bottom {
    transform-origin: bottom;
  }

  &--top {
    transform-origin: top;
  }

  &--left {
    transform-origin: left;
  }

  &--right {
    transform-origin: right;
  }

  &--sm {
    width: 175px;
  }

  &__arrow {
    position: absolute;
    transform: rotate(45deg);
    background: var(--dremio-tooltip--arrow--background);
    block-size: var(--dremio-tooltip--arrow--size);
    inline-size: var(--dremio-tooltip--arrow--size);
  }

  &__child-wrap {
    display: inline-block;
  }

  transition-property: opacity, transform;
  will-change: transform;
}

.echarts-tooltip {
  background-color: var(--dremio-tooltip--background) !important;
  border: var(--dremio-tooltip--border) !important;
}
