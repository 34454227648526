/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.rounded-none {
  border-radius: 0;
}
.rounded-sm {
  border-radius: 2px;
}

//@deprecated
.rounded {
  border-radius: 4px;
}
.rounded-md {
  border-radius: 6px;
}
.rounded-lg {
  border-radius: 8px;
}
.rounded-xl {
  border-radius: 12px;
}
.rounded-2xl {
  border-radius: 16px;
}
.rounded-3xl {
  border-radius: 24px;
}

.rounded-top-sm {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.rounded-bottom-sm {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
