/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "../../base/components/modal-container";

@keyframes dremio-modal-container-fadein {
  from {
    background: rgba(0 0 0 / 0%);
    /* stylelint-disable-next-line property-no-unknown */
    backdrop-filter: blur(0);
  }

  to {
    background: rgba(41 56 73 / 50%);
    /* stylelint-disable-next-line property-no-unknown */
    background-filter: blur(2px);
  }
}

// Override default <dialog> styles
dialog.dremio-modal-container {
  padding: 0;
}

.dremio-modal-container {
  --dremio--modal-container--background: var(--fill--primary);
  --dremio--modal-container--backdrop--background: rgba(41 56 73 / 50%);
  --dremio--modal-container--backdrop--blur: 2px;
  --dremio--modal-container--border: 1px solid var(--border--neutral);
  --dremio--modal-container--border-radius: var(--dremio--radius--1);
  --dremio--modal-container--shadow: var(--dremio--shadow--layer-1);
  --dremio--modal-container--window-margin: 30px;

  &::backdrop {
    animation: dremio-modal-container-fadein 100ms forwards;
  }
}
