/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.bar-group {
  display: flex;
  flex-direction: row;
  gap: 2px;
}
.bar {
  align-items: center;
  block-size: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;

  &__container {
    align-items: center;
    display: flex;
    flex: 1;
    gap: 1px;
    border-radius: 2px;
    padding: 6px;
    padding-bottom: 0;
    transition: background-color 125ms ease;
    width: 100%;

    &--stacked {
      flex-direction: column-reverse;
      justify-content: end;
    }

    &--grouped {
      align-items: end;
      flex-direction: row;
    }
  }
  &__segment {
    border-radius: 2px;
    max-width: 12px;
    width: 100%;
    min-width: 6px;
    display: flex;
    transition: height 400ms cubic-bezier(0.22, 1, 0.36, 1);

    &--bg-default {
      background-color: var(--color--brand--200);
    }
  }
  &__label {
    font-weight: 500;
    font-size: 0.875em;
    font-variant-numeric: lining-nums tabular-nums;
    letter-spacing: -0.025em;
    padding: 4px;
    text-wrap: nowrap;
  }
}

.viz-segment-list {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 8px 16px;
  font-size: 13px;

  dt {
    align-items: center;
    display: inline-flex;
    padding-inline-end: 4px;
  }

  dd {
    align-items: center;
    display: inline-flex;
    justify-content: end;
    text-align: start;
    word-break: break-all;
    font-weight: 600;
    font-variant-numeric: lining-nums tabular-nums;
    letter-spacing: -0.025em;
  }
}
