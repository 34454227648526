/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

:root {
  --font-family--default: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  :root {
    --font-family--default: "InterVariable", sans-serif;
    font-optical-sizing: auto;
  }
}

:root {
  --font-family--mono: "Fira Code", monospace;

  /* @deprecated */
  --dremio--font-family: var(--font-family--default);

  /* @deprecated */
  --dremio--font-family--monospace: var(--font-family--mono);

  /**
    Use rem in case the user has overridden their browser's default font size (not zoom)
  */
  --dremio--font-size--xs: calc(10rem / 14);
  --dremio--font-size--sm: calc(12rem / 14);
  --dremio--font-size--md: calc(14rem / 14);
  --dremio--font-size--lg: calc(16rem / 14);
  --dremio--font-size--xl: calc(18rem / 14);

  --dremio--font-size--default: var(--dremio--font-size--md);
}
