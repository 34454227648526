/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "../utilities/text";

:root {
  @extend .font-default;
  @extend .text-normal;

  background-color: var(--bg--sunken);
  color: var(--color--sunken);

  font-size: calc(14rem / 16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dremio-auto-system {
  @supports not (prefers-color-scheme: light) {
    color-scheme: light;
    ::selection {
      background: var(--color--brand--100);
      color: var(--text--primary);
    }
  }
  @media (prefers-color-scheme: light) {
    color-scheme: light;
    ::selection {
      background: var(--color--brand--100);
      color: var(--text--primary);
    }
  }
  @media (prefers-color-scheme: dark) {
    color-scheme: dark;
    ::selection {
      background: var(--color--brand--600);
      color: var(--text--primary);
    }
  }
  @media (prefers-contrast: more) {
    color-scheme: light;
    ::selection {
      background: var(--color--brand--100);
      color: var(--text--primary);
    }
  }
}

.dremio-light {
  color-scheme: light;
  ::selection {
    background: var(--color--brand--100);
    color: var(--text--primary);
  }
  @media (prefers-contrast: more) {
    ::selection {
      background: var(--color--brand--200);
      color: var(--text--primary);
    }
  }
}

.dremio-dark {
  color-scheme: dark;
  ::selection {
    background: var(--color--brand--600);
    color: var(--text--primary);
  }
}

mark {
  background: var(--fill--mark);
  border-radius: 2px;
  color: inherit;
}

input,
textarea {
  // Keep contextual alternates off so that the glyphs don't
  // shift around while the user types
  font-feature-settings: "calt" off;
}
