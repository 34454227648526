/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "../../base/components/skeleton";

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 140px;
  }
}

.dremio-skeleton {
  --dremio--skeleton--border-radius: var(--dremio--radius--1);
  background-image: linear-gradient(
    90deg,
    var(--border--neutral) 0px,
    rgba(229, 229, 229, 1) 40px,
    var(--border--neutral) 80px
  );
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
}

.dremio-dark .dremio-skeleton {
  background-image: linear-gradient(
    90deg,
    lch(40% 1 242) 0px,
    lch(43% 1 242) 40px,
    lch(40% 1 242) 80px
  );
}
