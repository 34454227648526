/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.dremio-description-list {
  display: grid;
  grid-template-columns: max-content 1fr;
  line-height: var(--dremio--description-list--line-height);
  inline-size: 100%;
  margin-block: calc(var(--dremio--description-list--padding-block) * -1);

  &__term,
  dt,
  &__description,
  dd {
    padding-block: var(--dremio--description-list--padding-block);
  }

  &__term,
  dt {
    align-items: center;
    color: var(--dremio--description-list--term--color);
    display: inline-flex;
    font-size: var(--dremio--description-list--term--font-size);
    padding-inline-end: var(--dremio--description-list--term--gap);
  }

  &__description,
  dd {
    align-items: center;
    color: var(--dremio--description-list--description--color);
    display: inline-flex;
    font-size: var(--dremio--description-list--description--font-size);
    justify-content: end;
    text-align: start;
    word-break: break-all;
  }
}
