/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.dremio-page {
  // Don't allow the height of the page to be taller than the viewport
  block-size: 100vh;
  display: flex;
  flex-direction: column;
  inline-size: 100%;

  &__header {
    z-index: 10;

    > * {
      padding-inline: var(--dremio--page--padding);
    }
  }

  &__content {
    // The content window should fill the available space (minus the size of the header and footer)
    flex: 1;

    /**
     * All content should fit within the available horizontal space -- if it doesn't,
     * horizontally scrolling the entire content area isn't a good solution.
     */
    overflow-x: hidden;

    // Vertical scrolling is allowed within the content area, and the page header/footer should remain in place.
    overflow-y: auto;

    // Add a little bit of overscroll at the bottom of the content area
    > *:last-child {
      padding-block-end: var(--dremio--page--padding);
    }

    > * {
      /**
       * Every child of the page should inherit a default amount of horizontal margin.
       * For any elements that want to bypass the margin, they can simply set `margin:0`
       */
      margin-inline: var(--dremio--page--padding);
    }
  }
}

.dremio-layout-container .dremio-page {
  block-size: auto;
}
