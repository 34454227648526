/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "../../base/components/dialog-content";
@use "../../utilities";

.dremio-dialog-content {
  --dremio--dialog-content--border: 1px solid var(--border--neutral);
  --dremio--dialog-content--color: var(--text--primary);
  --dremio--dialog-content--font-weight: 400;
  --dremio--dialog-content--header--height: 55px;
  --dremio--dialog-content--title--font-size: 16px;
  --dremio--dialog-content--title--font-weight: 600;
  --dremio--dialog-content--footer--height: var(
    --dremio--dialog-content--header--height
  );
  --dremio--dialog-content--border-radius: var(--dremio--radius--1);
  --dremio--dialog-content--padding: var(--dremio--spacing--2);
  --dremio--dialog-content--icon--size: 22px;
  --dremio--dialog-content--icon--color: var(--icon--primary);
}
