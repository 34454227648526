/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.section-tabs {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.section-tab {
  border-bottom: 2px solid transparent;
  color: inherit;
  font-weight: 400;
  padding-block-end: 8px;
  padding-inline: 8px;
  text-decoration: none !important;

  &:hover {
    color: var(--text--brand);
  }
  &:focus-visible {
    color: var(--text--brand);
    outline: 1px auto -webkit-focus-ring-color !important;
  }

  &[aria-current="true"],
  &[aria-current="page"],
  &[aria-selected="true"] {
    border-bottom-color: var(--border--brand--solid);
    color: var(--text--brand);
    font-weight: 600;
  }
}
