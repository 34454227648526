.elevation {
  &-sunken {
    background-color: var(--bg--sunken);
    color: var(--color--sunken);
  }
  &-default {
    background-color: var(--fill--primary);
    color: var(--text--primary);
  }
  &-raised {
    background-color: var(--bg--raised);
    color: var(--color--raised);
  }
  &-overlay {
    background-color: var(--bg--overlay);
    color: var(--color--overlay);
  }
}
