/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.popover {
  z-index: 1000;
  background-color: var(--popover--background);
  border-radius: var(--popover--radius);
  &--bottom {
    transform-origin: bottom;
  }

  &--top {
    transform-origin: top;
  }

  &--left {
    transform-origin: left;
  }

  &--right {
    transform-origin: right;
  }

  &__arrow {
    position: absolute;
    transform: rotate(45deg);
    left: var(--popover--arrow--left);
    background: var(--popover--arrow--background);
    block-size: var(--popover--arrow--size);
    inline-size: var(--popover--arrow--size);
  }

  transition-property: opacity, transform;
  will-change: transform;
}
