/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.loader-bars {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#eeeff1 0 0);
  background:
    var(--c) 0% 100%,
    var(--c) 50% 100%,
    var(--c) 100% 100%;
  animation: b2 1s infinite linear;
}
@keyframes b2 {
  0% {
    background-size:
      20% 100%,
      20% 100%,
      20% 100%;
  }
  20% {
    background-size:
      20% 60%,
      20% 100%,
      20% 100%;
  }
  40% {
    background-size:
      20% 80%,
      20% 60%,
      20% 100%;
  }
  60% {
    background-size:
      20% 100%,
      20% 80%,
      20% 60%;
  }
  80% {
    background-size:
      20% 100%,
      20% 100%,
      20% 80%;
  }
  100% {
    background-size:
      20% 100%,
      20% 100%,
      20% 100%;
  }
}
