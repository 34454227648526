/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "./shadow";
@use "../../utilities/bg";
@use "../../utilities/rounded";

.float-container {
  background: var(--fill--popover);
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 5000;
}

.float-container {
  @extend .rounded;
  @extend .drop-shadow-lg;
}

@mixin float-container-shown {
  opacity: 1;
}

@mixin float-container-hidden {
  opacity: 0;
}

.float-container {
  transition-property: opacity;
  will-change: opacity;

  transition-duration: 100ms;
  @include float-container-hidden;

  &-enter,
  &-appear {
    @include float-container-hidden;
  }

  &-enter-active,
  &-appear-active,
  &-appear-done,
  &-enter-done {
    @include float-container-shown;
  }

  &-exit {
    @include float-container-shown;
  }

  &-exit-active {
    @include float-container-hidden;
  }

  &-exit-done {
    display: none;
  }
}
