/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* stylelint-disable selector-class-pattern */
.mantine-Input-invalid {
  border-color: var(--fill--danger--solid) !important;
  color: inherit !important;
}

.mantine-InputWrapper-error {
  color: var(--fill--danger--solid) !important;
}

.mantine-InputWrapper-label {
  font-weight: 400 !important;
  inline-size: 100%;
  color: inherit;
}

.mantine-TextInput-icon--text {
  .mantine-TextInput-icon {
    color: var(--text--primary);
    background-color: var(--fill--secondary);
    inset: 1px;
    border-end-start-radius: var(--dremio--radius--1);
    border-start-start-radius: var(--dremio--radius--1);
  }
}

.mantine-ScrollArea-viewport > div > div {
  padding: var(--dremio--spacing--05) 0 !important;
}

.mantine-Select-dropdown {
  border: none;
  color: inherit;
  background-color: var(--fill--popover);
  border-radius: var(--dremio--radius--1);
}

.mantine-Select-item {
  border-radius: 0;
  color: var(--text--primary);
}

.mantine-Select-item[data-hovered="true"] {
  background-color: var(--fill--primary--hover);
}

.mantine-Select-item[data-selected="true"] {
  color: var(--text--primary);
  background-color: var(--fill--primary--selected);

  &:hover {
    background-color: var(--fill--primary--selected--hover);
  }
}

.mantine-Input-input {
  block-size: 32px;
  min-block-size: 32px;
  line-height: 32px;
  background-color: var(--fill--primary);
  color: var(--text--primary);
  border-color: var(--border--neutral--solid);
  &:not(:disabled):hover {
    border-color: var(--border--hover);
  }
  &:disabled {
    color: var(--text--disabled);
    background-color: var(--fill--disabled);
    opacity: 1;
  }
}

.mantine-Select-rightSection {
  pointer-events: none;
}

.mantine-NumberInput-control {
  border: none;
  color: var(--icon--primary);
  &:not(:disabled):hover {
    background-color: var(--fill--secondary);
  }
  &:disabled {
    color: var(--icon--disabled);
  }
}

.mantine-Checkbox-icon {
  color: var(--fill--primary);
}
.mantine-Checkbox-input {
  border-color: var(--border--neutral--solid);
  background-color: var(--fill--primary);
  &:hover {
    cursor: pointer;
    border-color: var(--border--hover);
  }
  &:focus {
    outline-offset: 2px;
    outline: 2px solid var(--fill--toggle--checked--hover);
  }
  // &:focus-visible
  &:checked {
    background-color: var(--fill--toggle--checked);
    border-color: var(--fill--toggle--checked);
    &:hover {
      background-color: var(--fill--toggle--checked--hover);
      border-color: var(--fill--toggle--checked--hover);
    }
  }
}

.mantine-Menu-dropdown {
  background-color: var(--fill--popover);
  border-color: transparent;
  .mantine-Menu-item {
    color: var(--text--primary);
    background-color: inherit;
    &:hover {
      background-color: var(--fill--primary--hover);
    }
  }
}
.mantine-Menu-divider {
  border-color: var(--border--neutral);
}
