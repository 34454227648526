/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "./bg-vars";

.bg-primary {
  @extend .bg-vars--primary;
}

.bg-secondary {
  @extend .bg-vars--secondary;
}

.bg-brand-solid {
  @extend .bg-vars--brand-solid;
}

.bg-popover {
  @extend .bg-vars--popover;
}

.bg-primary,
.bg-secondary,
.bg-popover,
.bg-brand-solid,
.bg-danger-solid {
  background: var(--bg);
  color: var(--text);
  border-color: var(--border--color);
}

.bg-disabled {
  background: var(--bg) !important;
  color: var(--text) !important;
  border-color: var(--border--color) !important;
}

@for $i from 0 through 7 {
  .bg-categorical-#{$i} {
    background: var(--fill--categorical--#{$i});
    color: var(--text--categorical--#{$i});
  }
}
