.color-faded {
  color: var(--text--faded);
}

.color-neutral-300 {
  color: var(--color--neutral--300);
}

.color-neutral-900 {
  color: var(--color--neutral--900);
}

.color-info-300 {
  color: var(--color--brand--200);
}

.color-info-400 {
  color: var(--color--cyan--400);
}

.color-success-300 {
  color: var(--color--green--300);
}

.color-danger-400 {
  color: var(--fill--danger--solid);
}
