:root {
  --scale-1: 8px;
  --scale-05: calc(var(--scale-1) * 0.5);
  --scale-075: calc(var(--scale-1) * 0.75);
  --scale-105: calc(var(--scale-1) * 1.5);
  --scale-2: calc(var(--scale-1) * 2);
  --scale-205: calc(var(--scale-1) * 2.5);
  --scale-3: calc(var(--scale-1) * 3);
  --scale-4: calc(var(--scale-1) * 4);
  --scale-405: calc(var(--scale-1) * 4.5);
  --scale-5: calc(var(--scale-1) * 5);
  --scale-505: calc(var(--scale-1) * 5.5);
  --scale-6: calc(var(--scale-1) * 6);
  --scale-7: calc(var(--scale-1) * 7);
  --scale-8: calc(var(--scale-1) * 8);
  --scale-9: calc(var(--scale-1) * 9);
  --scale-905: calc(var(--scale-1) * 9.5);
  --scale-10: calc(var(--scale-1) * 10);

  --scale-rel-1: calc(8em / 14);
  --scale-rel-05: calc(var(--scale-rel-1) * 0.5);
  --scale-rel-075: calc(var(--scale-rel-1) * 0.75);
  --scale-rel-105: calc(var(--scale-rel-1) * 1.5);
  --scale-rel-2: calc(var(--scale-rel-1) * 2);
  --scale-rel-205: calc(var(--scale-rel-1) * 2.5);
  --scale-rel-3: calc(var(--scale-rel-1) * 3);
  --scale-rel-4: calc(var(--scale-rel-1) * 4);
  --scale-rel-405: calc(var(--scale-rel-1) * 4.5);
  --scale-rel-5: calc(var(--scale-rel-1) * 5);
  --scale-rel-505: calc(var(--scale-rel-1) * 5.5);
  --scale-rel-6: calc(var(--scale-rel-1) * 6);
  --scale-rel-7: calc(var(--scale-rel-1) * 7);
  --scale-rel-8: calc(var(--scale-rel-1) * 8);
  --scale-rel-9: calc(var(--scale-rel-1) * 9);
  --scale-rel-905: calc(var(--scale-rel-1) * 9.5);
  --scale-rel-10: calc(var(--scale-rel-1) * 10);
}
