/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* stylelint-disable selector-class-pattern */
.dremio-dialog-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: var(--dremio--dialog-content--color);
  font-weight: var(--dremio--dialog-content--font-weight);
  border-radius: var(--dremio--dialog-content--border-radius);

  &__header,
  &__footer {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;

    &:empty {
      display: none;
    }
  }

  &__header {
    border-block-end: var(--dremio--dialog-content--border);
    block-size: var(--dremio--dialog-content--header--height);

    &-icon {
      margin-inline-end: var(--dremio--spacing--1);
      block-size: var(--dremio--dialog-content--icon--size);
      inline-size: var(--dremio--dialog-content--icon--size);
      margin-block-start: 0.225em;
      color: var(--dremio--dialog-content--icon--color);
    }

    &-title {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: var(--dremio--dialog-content--title--font-size);
      font-weight: var(--dremio--dialog-content--title--font-weight);
      gap: 6px;
      inline-size: 100%;
    }

    &-toolbar {
      display: flex;
    }
  }

  &__error {
    margin-block-start: var(--dremio--dialog-content--padding);
  }

  &__main {
    padding-block: var(--dremio--dialog-content--padding);
    overflow-y: auto;
  }

  &__footer {
    border-block-start: var(--dremio--dialog-content--border);
    display: flex;
    flex-direction: row;
    block-size: var(--dremio--dialog-content--footer--height);

    &:empty {
      display: none;
    }

    &-actions {
      margin-inline-start: auto;
    }
  }

  &--no-overflow {
    .dremio-dialog-content__main {
      display: flex;
      overflow: hidden;
    }
  }

  &--expanded {
    block-size: calc(100vh - var(--dremio--modal-container--window-margin));
  }

  > * {
    padding-inline: var(--dremio--dialog-content--padding);
  }
}
