/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "../../base/components/tooltip";

@mixin dremio-tooltip-shown {
  opacity: 1;
  transform: scale(1) translateY(0);
}

@mixin dremio-tooltip-hidden {
  opacity: 0;
  transform: scale(0.8);
}
.echarts-tooltip {
  --dremio-tooltip--background: var(--fill--popover);
  --dremio-tooltip--border: transparent;
}

.dremio-tooltip {
  --dremio-tooltip--background: var(--color--neutral--700);
  --dremio-tooltip--border-radius: var(--dremio--radius--05);
  --dremio-tooltip--color: white;
  --dremio-tooltip--line-height: 20px;
  --dremio-tooltip--font-size: 14px;
  --dremio-tooltip--font-weight: 400;
  --dremio-tooltip--padding: var(--dremio--spacing--1);
  --dremio-tooltip--shadow: var(--dremio--shadow--layer-1);

  --dremio-tooltip--arrow--background: var(--dremio-tooltip--background);
  --dremio-tooltip--arrow--size: 8px;

  &-rich {
    --dremio-tooltip--background: var(--fill--popover);
    --dremio-tooltip--color: var(--text--primary);
  }

  transition-duration: 100ms;
  @include dremio-tooltip-hidden;

  &-enter,
  &-appear {
    @include dremio-tooltip-hidden;
  }

  &-enter-active,
  &-appear-active,
  &-appear-done,
  &-enter-done,
  &--open {
    @include dremio-tooltip-shown;
  }

  &-exit {
    @include dremio-tooltip-shown;
  }

  &-exit-active {
    @include dremio-tooltip-hidden;
  }
}
