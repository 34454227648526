/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.dremio-scroll-shadow {
  position: relative;
  overflow: visible;

  &::after {
    content: "";
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity 72ms ease-in-out;
    z-index: 500;
  }

  &--top::after {
    box-shadow: 0 10px 10px -10px rgba(0 0 0 / 10%) inset;
    inset-block-end: 0;
    inset-inline: 0;
    block-size: 30px;
    transform: translate(0, 100%);
  }

  &--bottom::after {
    box-shadow: 0 -10px 10px -10px rgba(0 0 0 / 10%) inset;
    inset-block-start: 0;
    inset-inline: 0;
    block-size: 30px;
    transform: translate(0, -100%);
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.--scrolled::after {
    opacity: 1;
  }
}
