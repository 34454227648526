/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "../../utilities/index";

.language-sql {
  color: var(--text--faded);

  .hljs {
    &-keyword {
      color: var(--sql--syntax--keywords);
    }
    &-string {
      color: var(--sql--syntax--string);
    }
    &-number {
      color: var(--sql--syntax--number);
    }
    &-built_in {
      color: var(--sql--syntax--function);
    }
    &-type {
      color: var(--sql--syntax--datatype);
    }
    &-comment {
      color: var(--sql--syntax--comment);
    }
  }
}

.syntax-highlighter {
  &--nowrap {
    code {
      white-space: nowrap !important;
    }
  }
}
