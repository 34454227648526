/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */

@import "@styles/constants/fonts.scss";

.login {
  height: 100vh;
  background-color: var(--fill--login);
  display: flex;
  flex-direction: column;

  &__root {
    padding-top: 32px;
    flex-grow: 1;
  }

  &__logo {
    width: 130px;
    height: 40px;
    margin: 35px 0px 70px 100px;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__spinner {
    animation: 1500ms linear infinite spin;
  }

  &__spinnerWrapper {
    height: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
      height: 24px;
    }
  }

  &__messagePlaceholder {
    margin-bottom: 30px;
  }

  &__orgList {
    max-height: 350px;
    overflow-y: auto;
  }

  &__orgListItem {
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border--neutral--solid);
    cursor: pointer;
    font-size: $font-xs;
    color: var(--text--primary) !important;
    text-decoration: none !important;
    &:hover {
      background-color: var(--fill--primary--hover);
    }

    &.--last {
      border-bottom: none;
    }
  }

  &__privacyPolicyLink {
    font-size: 12px;
    line-height: 18px;
    width: max-content;
    text-decoration: none;

    &.service-link {
      border-right: 1px solid var(--border--neutral--solid);
      border-left: 1px solid var(--border--neutral--solid);
    }
  }

  &__messageWrapper {
    height: max-content;
    margin-top: 3px;
    margin-bottom: -3px;
    max-width: calc(100vw - 48px); // 24px padding on each side

    svg {
      min-width: 24px;
    }
  }

  &__messageWrapper .simpleMessage__text {
    max-width: 873px;
  }

  .MuiGrid-root.MuiGrid-container {
    width: auto;
    margin: 0 auto;
  }
}

.loginForm {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__container {
    min-width: 921px;
    min-height: 440px;
    display: flex;
    border-radius: 8px;
    background-color: var(--fill--primary);
    flex-direction: column;
    padding: 24px;

    &.--wide {
      min-width: 1115px;
    }

    &.--tall {
      min-height: 500px;
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    color: var(--text--primary);
    line-height: 24px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
