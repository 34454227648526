/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "../../base/components/popover";

@mixin popover-shown {
  opacity: 1;
  transform: scale(1) translateY(0);
}

@mixin popover-hidden {
  opacity: 0;
  transform: scale(0.8);
}

.popover {
  --popover--background: var(--fill--popover);
  --popover--arrow--left: -4px;
  --popover--arrow--background: var(--fill--popover);
  --popover--arrow--size: 8px;
  --popover--radius: 4px;

  transition-duration: 100ms;
  @include popover-hidden;

  &-enter,
  &-appear {
    @include popover-hidden;
  }

  &-enter-active,
  &-appear-active,
  &-appear-done,
  &-enter-done {
    @include popover-shown;
  }

  &-exit {
    @include popover-shown;
  }

  &-exit-active {
    @include popover-hidden;
  }
}
