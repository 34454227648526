/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.dremio-typography-monospace {
  font-family: var(--dremio--font-family--monospace);
  font-feature-settings: "zero" on;
  font-variant-ligatures: none;
  font-weight: 400;
}

.dremio-typography-bold {
  font-weight: 600;
}

.dremio-typography-disabled {
  color: var(--text--disabled);
}

.dremio-typography-extra-large {
  font-size: 18px;
  line-height: 28px;
}

.dremio-typography-large {
  font-size: 16px;
  line-height: 24px;
}

.dremio-typography-medium {
  font-size: 14px;
  line-height: 20px;
}

.dremio-typography-small {
  font-size: 12px;
  line-height: 18px;
}

.dremio-typography-extra-small {
  font-size: 10px;
  line-height: 14px;
}

.dremio-typography-less-important {
  color: var(--text--faded);
}

/**
 * This class should be used whenever strictly numeric data (such as timestamps, durations,
 * quantities, etc.) is displayed vertically and the length of the content may be significant.
 * This would be the case in table columns, <li>s where numeric content naturally aligns, etc.
 */
.dremio-typography-tabular-numeric {
  font-variant-numeric: lining-nums tabular-nums;
  letter-spacing: -0.025em;
}
