/**
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "../../utilities/height";

.tabpanel-tablist {
  display: flex;

  button {
    box-sizing: border-box;

    color: var(--text--faded);
    background-color: transparent;

    border: none;

    @extend .h-8;
    width: 160px;

    position: relative;

    &[aria-selected="true"] {
      color: var(--text--brand);

      &:after {
        content: "";
        width: 100%;
        height: 4px;
        background-color: var(--fill--brand--solid);

        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    &:hover,
    &:focus {
      color: var(--text--brand);
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--text--disabled);
      &:after {
        background-color: var(--color--neutral--200);
      }
    }
  }
}
