/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.dremio-section {
  border: var(--dremio--section--border);
  border-radius: var(--dremio--section--border-radius);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__header {
    align-items: center;
    border-block-end: var(--dremio--section--header--border);
    display: flex;
    flex-direction: row;
    line-height: var(--dremio--section--header--line-height);
  }

  &__title {
    align-items: center;
    display: inline-flex;
  }

  &__title,
  &__title > * {
    font-size: var(--dremio--section--title--font-size);
    font-weight: var(--dremio--section--title--font-weight);
    margin: 0;
    padding: 0;
  }

  &__toolbar {
    margin-inline-start: auto;
  }

  &__body {
    flex: 1;
    overflow-y: auto;
    padding: var(--dremio--section--body--padding);
  }
}
