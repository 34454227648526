/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */

.toggle {
  .Mui-checked.MuiSwitch-colorPrimary {
    color: var(--color--brand--300) !important;
  }

  .Mui-checked.MuiSwitch-thumb {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15) !important;
  }

  .MuiSwitch-track {
    background-color: var(--color--brand--200) !important;
  }

  .Mui-checked.Mui-disabled {
    color: var(--dremio--color--pale--navy) !important;

    & + .MuiSwitch-track {
      opacity: 1 !important;
      background-color: var(--color--brand--50) !important;
    }
  }
}

.cookieConsent {
  width: 600px;
  position: absolute;
  left: 16px;
  bottom: 16px;
  font-size: 14px;
  background-color: var(--fill--primary);
  border-radius: 4px;
  box-shadow: var(--dremio--shadow--layer-1);

  &__content {
    display: flex;
    justify-content: space-between;

    &--text {
      width: 515px;
      height: 117px;
      line-height: 20px;
      margin: 0;
    }

    &--icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      color: var(--icon--primary);

      &:hover {
        color: var(--icon--hover);
      }
    }
  }

  &__footer {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid var(--border--neutral);
  }
}

.cookiePreferences {
  width: 600px !important;
  height: 536px !important;
  color: var(--text--primary) !important;
  font-size: 14px;

  &__field {
    display: flex;
    align-items: center;

    &__description {
      font-size: 12px;
      line-height: 18px;
      color: var(--text--faded);
    }
  }
}
