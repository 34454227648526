/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.dremio-segmented-control {
  display: flex;
  flex-direction: row;
  gap: var(--dremio--segmented-control-option--gap);

  > *:first-child {
    border-start-start-radius: var(--dremio--segmented-control--border-radius);
    border-end-start-radius: var(--dremio--segmented-control--border-radius);
    padding-inline-start: calc(
      var(--dremio--segmented-control-option--padding) + 1px
    );
  }

  > *:last-child {
    border-start-end-radius: var(--dremio--segmented-control--border-radius);
    border-end-end-radius: var(--dremio--segmented-control--border-radius);
    padding-inline-end: calc(
      var(--dremio--segmented-control-option--padding) + 1px
    );
  }

  &-option {
    background: var(--dremio--segmented-control-option--background);
    border: var(--dremio--segmented-control-option--border);
    color: var(--dremio--segmented-control-option--color);
    font-weight: var(--dremio--segmented-control-option--font-weight);
    padding: var(--dremio--segmented-control-option--padding);
  }
}
