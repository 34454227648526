/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "./helpers";

.ml-auto {
  margin-inline-start: auto;
}
.m-auto {
  margin: auto;
}
.m-0 {
  margin: 0;
}

@each $size in helpers.$sizes {
  .m-#{$size} {
    margin: var(--scale-#{$size});
  }
}

@each $size in helpers.$sizes {
  @each $direction in t, r, b, l, x, y {
    .m#{$direction}-#{$size} {
      @if $direction == t {
        margin-block-start: var(--scale-#{$size});
      }
      @if $direction == b {
        margin-block-end: var(--scale-#{$size});
      }
      @if $direction == l {
        margin-inline-start: var(--scale-#{$size});
      }
      @if $direction == r {
        margin-inline-end: var(--scale-#{$size});
      }
      @if $direction == x {
        margin-inline: var(--scale-#{$size});
      }
      @if $direction == y {
        margin-block: var(--scale-#{$size});
      }
    }
  }
}
