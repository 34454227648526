/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

:root {
  --dremio--spacing--05: calc(var(--dremio--spacing--1) * 0.5);
  --dremio--spacing--1: 8px;
  --dremio--spacing--105: calc(var(--dremio--spacing--1) * 1.5);
  --dremio--spacing--2: calc(var(--dremio--spacing--1) * 2);
  --dremio--spacing--3: calc(var(--dremio--spacing--1) * 3);
  --dremio--spacing--4: calc(var(--dremio--spacing--1) * 4);
  --dremio--spacing--5: calc(var(--dremio--spacing--1) * 5);
  --dremio--spacing--6: calc(var(--dremio--spacing--1) * 6);
  --dremio--spacing--7: calc(var(--dremio--spacing--1) * 7);
  --dremio--spacing--8: calc(var(--dremio--spacing--1) * 8);
  --dremio--spacing--9: calc(var(--dremio--spacing--1) * 9);
  --dremio--spacing--10: calc(var(--dremio--spacing--1) * 10);
  --dremio--spacing--15: calc(var(--dremio--spacing--1) * 15);
}
