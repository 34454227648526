/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@use "../../utilities/align";
@use "../../utilities/bg";
@use "../../utilities/border";
@use "../../utilities/flex";
@use "../../utilities/height";
@use "../../utilities/overflow";
@use "../../utilities/padding";
@use "../../utilities/rounded";
@use "../../utilities/width";

.form-control {
  @extend .align-baseline;
  @extend .flex;
  @extend .flex-row;
  @extend .h-4;
  @extend .items-center;
  @extend .overflow-hidden;
  @extend .p-05;
  @extend .rounded;
  @extend .w-full;

  background: var(--fill--primary);
  color: var(--text--primary);
  border: 1px solid var(--border--neutral--solid);

  box-sizing: border-box;
  font-size: inherit;
  outline: none;

  &:hover {
    border: 1px solid var(--border--hover);
  }

  &:focus,
  &:focus-within {
    border: 1px solid var(--border--focused);
  }

  input {
    @extend .border-none;
    @extend .flex-1;
    @extend .h-full;
    appearance: none;
    background: transparent;
    outline: none;

    &:disabled,
    &[aria-disabled="true"] {
      @extend .bg-disabled;
      cursor: not-allowed !important;
      border-color: var(--border--neutral--solid) !important;
    }
  }

  &__prefix {
    background-color: var(--fill--secondary);
    padding-block: 5px;
    margin-inline-start: -4px;
    margin-inline-end: 4px;
    padding-inline: 4px;
  }

  @at-root input#{&}:not([type="radio"]):not([type="checkbox"]) {
    padding-inline: 0.5em;
  }

  &:disabled,
  &[aria-disabled="true"] {
    @extend .bg-disabled;
    cursor: not-allowed !important;
    border-color: var(--border--neutral--solid) !important;
  }

  &:invalid,
  &[aria-invalid="true"] {
    border-color: var(--color--danger--400);
  }

  &[type="checkbox"] {
    border-radius: 0.2em;
    width: 16px;
    min-width: var(--scale-2);
    height: 16px;

    &:disabled {
      border-color: var(--bg) !important;
    }

    &:checked {
      background: var(--fill--toggle--checked);
      border: 1px solid var(--fill--toggle--checked);

      &::after {
        background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iaW50ZXJmYWNlL2NoZWNrYm94IiB2aWV3Qm94PSIwIDAgMTIgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC40Njk3IDAuOTY4OTQ2TDMuOTk5NjggNy40Mzg5NUwxLjUyOTY4IDQuOTY4OTVMMS40NDU2OCA0Ljg5NTk1QzEuMjkzNDcgNC43ODQzIDEuMTA0NzYgNC43MzQxNSAwLjkxNzIgNC43NTU1MUMwLjcyOTY0NCA0Ljc3Njg3IDAuNTU3MDQxIDQuODY4MTcgMC40MzM4MzggNS4wMTExOUMwLjMxMDYzNiA1LjE1NDIxIDAuMjQ1ODk3IDUuMzM4NDMgMC4yNTI1NCA1LjUyNzA5QzAuMjU5MTg0IDUuNzE1NzQgMC4zMzY3MjEgNS44OTQ5NSAwLjQ2OTY3OSA2LjAyODk1TDMuNDY5NjggOS4wMjg5NUwzLjU1MzY4IDkuMTAxOTVDMy42OTgxNSA5LjIwODY2IDMuODc2MTIgOS4yNTk5OCA0LjA1NTIzIDkuMjQ2NTlDNC4yMzQzNCA5LjIzMzE5IDQuNDAyNjkgOS4xNTU5NiA0LjUyOTY4IDkuMDI4OTVMMTEuNTI5NyAyLjAyODk1TDExLjYwMjcgMS45NDQ5NUMxMS43MTQzIDEuNzkyNzQgMTEuNzY0NSAxLjYwNDAyIDExLjc0MzEgMS40MTY0N0MxMS43MjE4IDEuMjI4OTEgMTEuNjMwNSAxLjA1NjMxIDExLjQ4NzQgMC45MzMxMDZDMTEuMzQ0NCAwLjgwOTkwNCAxMS4xNjAyIDAuNzQ1MTY0IDEwLjk3MTUgMC43NTE4MDhDMTAuNzgyOSAwLjc1ODQ1MSAxMC42MDM3IDAuODM1OTg5IDEwLjQ2OTcgMC45Njg5NDZaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4=");
        background-repeat: no-repeat;
        background-position: center;
        content: "";
        position: absolute;
        inset: 10%;
      }

      &:disabled::after {
        filter: invert(0.25);
      }

      &:hover {
        background: var(--fill--toggle--checked--hover);
        border: 1px solid var(--fill--toggle--checked--hover);
      }
    }

    &:focus-visible {
      outline-offset: 4px;
      outline: 1.5px solid var(--border--focused);
    }

    &:indeterminate {
      border: 1.5px solid var(--fill--toggle--checked);
      &::after {
        content: "";
        display: block;
        color: white;
        width: 8px;
        height: 8px;
        background-color: var(--fill--toggle--checked);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        &::after {
          background-color: var(--fill--toggle--checked--hover);
        }
        border: 1.5px solid var(--fill--toggle--checked--hover);
      }
    }

    &:checked,
    &:indeterminate {
      padding: 0;
    }
  }

  &[type="checkbox"] {
    appearance: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 !important;
    position: relative;
  }
  &[type="radio"] {
    appearance: none;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    position: relative;
  }

  &[type="radio"] {
    border-radius: 50%;
    width: 16px;
    height: 16px;

    &:checked {
      border: 1px solid var(--fill--toggle--checked);
      background: var(--fill--toggle--checked);

      &:hover {
        background-color: var(--fill--toggle--checked--hover);
        border: 1px solid var(--fill--toggle--checked--hover);
      }
    }

    &::after {
      background: var(--fill--primary);
      content: "";
      position: absolute;
      inset: 100%;
      border-radius: 50%;
    }

    &:checked::after {
      inset: 20%;
    }
    &:hover {
      border: 1px solid var(--border--hover);
    }
    &:focus-visible {
      outline-offset: 4px;
      outline: 1.5px solid var(--border--focused);
    }
  }
}

.dremio-dark {
  .form-control {
    &[type="checkbox"] {
      &:checked {
        &::after {
          filter: invert(1);
        }

        &:disabled::after {
          filter: invert(0.6);
        }
      }
    }
  }
}
