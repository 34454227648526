/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "../../base/components/section-message";
@use "../../utilities";

.dremio-section-message {
  --dremio-section-message--color: var(--text--primary);
  --dremio-section-mesage--header--font-size: 12px;
  --dremio-section-message--padding: var(--dremio--spacing--1);

  --dremio-section-mesage--header--font-weight: 600;

  gap: var(--dremio--spacing--1);

  &__content {
    line-height: 20px;

    > * + * {
      margin-block-start: var(--dremio--spacing--05);
    }
  }

  &--danger {
    --dremio-section-message--background: var(--fill--danger);
  }

  &--warning {
    --dremio-section-message--background: var(--fill--warning);

    dremio-icon {
      color: var(--fill--warning--solid);
    }
  }

  &--information {
    --dremio-section-message--background: var(--fill--info);

    dremio-icon {
      color: var(--fill--info--solid);
    }
  }

  &--success {
    --dremio-section-message--background: var(--fill--success);

    dremio-icon {
      color: var(--fill--success--solid);
    }
  }

  &--discovery {
    --dremio-section-message--background: lch(93 7.31 286.07);
    --dremio-section-message--color: lch(5 36.31 292.07);
    dremio-icon {
      color: lch(26 61.31 292.07);
    }
  }
}
