/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.drop-shadow-lg {
  box-shadow: var(--dremio--shadow--layer-1);
  // filter: drop-shadow(1px 2px 3px hsl(220deg 0% 50% / 0.2))
  // drop-shadow(2px 4px 6px hsl(220deg 0% 50% / 0.1))
  // drop-shadow(4px 8px 12px hsl(220deg 0% 50% / 0.1));
}
