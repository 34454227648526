/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.dremio-icon-button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  user-select: none;
  color: var(--icon--primary);
  padding: var(--dremio--spacing--05);
  transition: color 125ms ease-in-out;
  vertical-align: middle;

  &:hover,
  &:focus-visible,
  &[aria-expanded="true"] {
    color: var(--icon--hover);
  }

  &:disabled,
  &[aria-disabled="true"] {
    cursor: not-allowed;
    pointer-events: none;
    color: var(--icon--disabled);
  }

  // Restrict clickable area to button (Useful when Icon overflows the bound of the button)
  > * {
    pointer-events: none;
  }

  dremio-icon {
    block-size: 24px;
    inline-size: 24px;
  }
}

button.dremio-icon-button {
  background: transparent;
  border: none;
}

a.dremio-icon-button:focus-visible {
  outline: -webkit-focus-ring-color auto 1px !important;
}
