/*
 * Copyright (C) 2017-2019 Dremio Corporation. This file is confidential and private property.
 */

@import "@styles/constants/fonts.scss";

.socialSigninButton {
  display: flex;
  color: white;
  width: 250px;
  background-color: var(--color--brand--300);
  border-radius: 4px;
  border: thin solid var(--border--neutral--solid);
  white-space: nowrap;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &:hover {
    text-decoration: none !important;
  }

  &__google {
    background-color: #4285f4;
    border-color: #4285f4;
  }
  &__microsoft {
    background-color: #535353;
    border-color: #535353;
  }
  &__github {
    background-color: #000;
    border-color: #000;
  }

  &__azuread {
    background-color: #0167b8;
    border-color: #0167b8;
  }

  &__okta {
    background-color: #007dc1;
    border-color: #007dc1;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 36px;
    height: 36px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    img {
      height: 22px;
      width: 22px;
    }
  }

  &__text {
    flex: 1;
    text-align: center;
    height: 100%;
    font-size: 12px;
    color: white;
    font-weight: 300;
  }
}
