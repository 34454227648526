/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "./helpers";

.border {
  border-width: 1px;
}

.border-none {
  border-style: none;
}

.border-solid {
  border-style: solid;
}

.border-transparent {
  border-color: transparent;
}

@each $shade in helpers.$shades {
  @each $grade in helpers.$gradeScale {
    .border-#{$shade}-#{$grade} {
      border-color: var(--color--#{$shade}--#{$grade});
    }
  }
}

.border-foreground {
  border-color: var(--border--neutral);
}

.border-danger-bold {
  border-color: var(--fill--danger--solid);
}

.dremio-dark {
  .border-foreground {
    border-color: var(--color--neutral--500);
  }
}

/**
 * New theming classes and variables below
 */

.border-thin {
  border: 1px solid var(--border--color);
}
