/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.self-start {
  align-self: start;
}
.self-end {
  align-self: end;
}
.self-center {
  align-self: center;
}
.self-baseline {
  align-self: baseline;
}
.items-start {
  align-items: start;
}
.items-end {
  align-items: end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.justify-start {
  justify-content: start;
}
.justify-end {
  justify-content: end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.min-width-0 {
  min-width: 0;
}
.flex-1 {
  flex: 1;
}
.flex-initial {
  flex: 0 1 auto;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.grow {
  flex-grow: 1;
}
.shrink {
  flex-shrink: 1 !important;
}
.shrink-0 {
  flex-shrink: 0;
}
