/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@use "../../base/components/drawer";

.dremio-drawer {
  background: var(--fill--primary);
  border: none;
  border-start-start-radius: var(--dremio--radius--1);
  border-start-end-radius: var(--dremio--radius--1);
  box-shadow: var(--dremio--shadow--left);

  .dremio-dialog-content {
    --dremio--dialog-content--padding: var(--dremio--spacing--1);
  }
}
