/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.dremio-avatar {
  align-items: center;
  box-sizing: content-box;
  contain: strict;
  display: inline-flex;
  flex-shrink: 0;
  float: left;
  justify-content: center;
  overflow: hidden;
  text-transform: uppercase;
  user-select: none;
  background: var(--dremio--avatar--background);
  block-size: var(--dremio--avatar--size);
  border: var(--dremio--avatar--border);
  border-radius: var(--dremio--avatar--border-radius);
  color: var(--dremio--avatar--color);
  font-size: var(--dremio--avatar--font-size);
  font-weight: var(--dremio--avatar--font-weight);
  inline-size: var(--dremio--avatar--size);
  letter-spacing: var(--dremio--avatar--letter-spacing);

  &--image > * {
    block-size: 100%;
    inline-size: 100%;
    object-fit: contain;
  }
}
