/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "../../base/components/avatar";

.dremio-avatar {
  --dremio--avatar--background: #333;
  --dremio--avatar--border: none;
  --dremio--avatar--border-radius: 100%;
  --dremio--avatar--color: white;
  --dremio--avatar--font-size: 0.785em;
  --dremio--avatar--font-weight: 600;
  --dremio--avatar--size: 2.1875em;
}

.dremio-light .dremio-avatar {
  &--color-1 {
    --dremio--avatar--background: var(--color--red-cool--500);
    --dremio--avatar--color: white;
  }

  &--color-2 {
    --dremio--avatar--background: var(--color--orange-warm--300);
    --dremio--avatar--color: #202124;
  }

  &--color-3 {
    --dremio--avatar--background: var(--color--orange--150);
    --dremio--avatar--color: #202124;
  }

  &--color-4 {
    --dremio--avatar--background: var(--color--blue-warm--300);
    --dremio--avatar--color: white;
  }

  &--color-5 {
    --dremio--avatar--background: #63daff;
    --dremio--avatar--color: #202124;
  }

  &--color-6 {
    --dremio--avatar--background: var(--color--violet-warm--500);
    --dremio--avatar--color: white;
  }

  &--color-7 {
    --dremio--avatar--background: var(--color--magenta--300);
    --dremio--avatar--color: #202124;
  }

  &--color-8 {
    --dremio--avatar--background: var(--color--indigo--300);
    --dremio--avatar--color: #202124;
  }

  &--color-9 {
    --dremio--avatar--background: var(--color--mint--300);
    --dremio--avatar--color: #202124;
  }

  &--color-0 {
    --dremio--avatar--background: var(--color--green--200);
    --dremio--avatar--color: #202124;
  }
}

.dremio-dark .dremio-avatar {
  &--color-1 {
    --dremio--avatar--background: #79292c;
    --dremio--avatar--color: white;
  }

  &--color-2,
  &--color-3 {
    --dremio--avatar--background: #8e5804;
    --dremio--avatar--color: white;
  }

  &--color-4,
  &--color-5 {
    --dremio--avatar--background: #275888;
    --dremio--avatar--color: white;
  }

  &--color-6 {
    --dremio--avatar--background: #54286d;
    --dremio--avatar--color: white;
  }

  &--color-7 {
    --dremio--avatar--background: #8d2f66;
    --dremio--avatar--color: white;
  }

  &--color-8 {
    --dremio--avatar--background: #432f71;
    --dremio--avatar--color: white;
  }

  &--color-9 {
    --dremio--avatar--background: #005354;
    --dremio--avatar--color: white;
  }

  &--color-0 {
    --dremio--avatar--background: #105434;
    --dremio--avatar--color: white;
  }
}
