/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "../../utilities";

.dremio-button {
  @extend .items-center;
  @extend .flex-row;
  @extend .overflow-hidden;
  @extend .no-select;

  box-sizing: border-box;
  display: inline-flex;
  max-inline-size: 100%;
  vertical-align: middle;

  &:disabled,
  &[aria-disabled="true"] {
    cursor: not-allowed;
  }

  &__content {
    @extend .items-center;
    @extend .flex-1;
    @extend .flex-row;
    @extend .justify-center;
    @extend .overflow-hidden;
    @extend .whitespace-nowrap;
    display: inline-flex;

    > * {
      @extend .shrink-0;
      max-block-size: 100%;
    }
  }
}

a.dremio-button {
  text-decoration: none !important;
}

a.dremio-button:focus-visible {
  outline: -webkit-focus-ring-color auto 1px !important;
}

button.dremio-button {
  padding: 0;
}

.dremio-button {
  .dremio-tooltip__child-wrap {
    flex: 1;
  }
}
