.dremio-external-link {
  &--inline {
    /* stylelint-disable-next-line selector-class-pattern */
    .dremio-external-link__icon {
      block-size: 12px;
      inline-size: 12px;
      margin-block-start: -5px;
      margin-inline-start: -1px;
    }
  }

  &--list {
    /* stylelint-disable-next-line selector-class-pattern */
    .dremio-external-link__icon {
      block-size: 20px;
      inline-size: 20px;
    }
  }
}
